@media (min-width: 768px) {
  .Toplists {
    margin: 0 10px;
  }
}

.Toplists .lander {
  padding: 80px 0;
  text-align: center;
}

.Toplists .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Toplists .lander p {
  color: #999;
}

.Toplists .toplists h4 {
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
  line-height: 1.2;
  font-size: 1.0rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Toplists .toplists p {
  color: #888;
  margin-bottom: 0;
}

.Toplists {
  margin-top: 20px;
}

.Toplists .details {
  font-size: 0.8rem;
}

a:focus {
  outline: none !important;
}