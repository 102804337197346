.up {
    color: green;
    padding-left: .2em;
}

.down {
    color: red;
    padding-left: .2em;
}

.react-bootstrap-table table {
    table-layout: auto;
}

.Universe {
    padding-bottom: 100px;
}

.Universe td {
    white-space: nowrap;
}

th:focus {
    outline: none !important;
}

span.soon {
    color: green;
}

span.very_soon {
    color: green;
    font-weight: bold;
}

span.previous {
    color: grey;
}

a.link {
    text-decoration: none;
    padding-right: 5px;
}

span.links {
    float: right;
}

.paginator {
    justify-content: center;
    display: flex;
    background-color: white;
    padding-top: 10px;
    border-top: 1px solid lightgray;
}

.dropup {
    padding-right: 5px;
}

.delete_button {
    margin-right: 4px;
}

.checkmark {
    font-size: 22px;
    color: green;
    vertical-align: bottom;
    margin-right: 12px;
    line-height: 26px;
}