.btn-outline-secondary {
  border-color: #ced4da;
}

.list-group-item .approve {
  margin-top: 10px;
}

.list-group-item .skip {
  margin-top: 10px;
  margin-left: 10px;
}