@media all and (min-width: 480px) {
  .Settings {
    padding: 60px 0;
    display: flex;
  }
}

.controls {
    display: flex;
    width: 640px;
    flex-direction: row;
    justify-content: center;
}

.range-slider__wrap {
    flex-basis: 50%;
}

.LoaderButton {
    margin:0 0 0 20px;
}
