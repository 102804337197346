.up {
    color: green;
    padding-left: .2em;
}

.down {
    color: red;
    padding-left: .2em;
}

.new {
    color: darkgrey;
    float: right;
    font-size: smaller;
}

.react-bootstrap-table table {
    table-layout: auto;
}

.Toplist {
    padding-bottom: 100px;
}

.Toplist td {
    white-space: nowrap;
}

th:focus {
    outline: none !important;
}

span.soon {
    color: green;
}

span.very_soon {
    color: green;
    font-weight: bold;
}

span.previous {
    color: grey;
}

a.link {
    text-decoration: none;
    padding-right: 5px;
}

span.links {
    float: right;
}

.paginator {
    justify-content: center;
    display: flex;
    background-color: white;
    padding-top: 10px;
    border-top: 1px solid lightgray;
}

.dropup {
    padding-right: 5px;
}

label {
    margin-bottom: 0px;
}

.first_contact {
    font-weight: bold;
    color: green;
}